<template>
  <footer>
    <v-container class="wrap-items-footer">
      <svg width="80" class="path-pesta" height="28" viewBox="0 0 80 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12 15C10 7.8 3.83333 6 1 6L0.5 3H63C66.6667 4 72.2 6 65 6C56 6 53.5 11.5 52.5 14C51.7 16 50.5 17.8333 50 18.5C48.6667 19 45.6 20.7 44 23.5C42.4 26.3 32.6667 27.6667 28 28C25 27.5 14.5 24 12 15Z"
            fill="white" />
        </svg>

      <div class="wrap-ask">
        <v-avatar size="40px" class="pa-2" color="primary" @click="openWhatsapp" >
        <div style="display: flex; justify-content: center;align-items: center;">
          <v-icon color="white" size="28">fa fa-whatsapp</v-icon>
        </div>
        </v-avatar>
      </div>
     <h4 class="ask-title"> Preguntas <br> Frecuentes</h4>
      <div class="icon-wrap justify-start">
        <img src="@/assets/caraFooter2.svg" class="image" />
        <div class="wrap-contact">
          <a href="mailto:experienciacliente@clubdecompras.hn" style="color: #fff">experienciacliente@clubdecompras.hn</a>
          <a href="tel:+50494438318" style="color: #fff">+504 94438318</a>
        </div>
      </div>
    </v-container>
  </footer>
</template>
<script>

export default {
  name: "footer-vue",
  data() {
    return {
      whatsappNumber: '50494438318'
    };
  },
  methods: {
    openWhatsapp() {
      window.open(`https://wa.me/${this.whatsappNumber}`, '_blank');
    }
  } 
};
</script>
<style lang="scss" scoped>
@import "./../responsive";

footer {
  width: calc(100% - 20px);
  margin-left: 10px;
  height: 80px;
  background-color: #36A8E0;
  // position: absolute;
  //   bottom: 0;
  z-index: 90;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 20px 20px 0px 0px;

  @include responsive(mobile) {
    position: relative !important;
    height: auto;
  }

  a {
    color: #fff;
    text-decoration: none;
    font-size: 12px;
    transition: 0.3s ease-in-out;

    &:hover {
      text-decoration: underline;
    }
  }
}
.wrap-items-footer{
  display: flex;
  justify-content: end;
}
.icon-wrap {
  display: flex;
  background-color: #36A8E0;

  .image {
    height: 34px;
    margin-right: 5px;

  }

  .wrap-contact {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.wrap-ask{
  position: absolute;
  left: 36px;
  top: -28px;
  border: solid 5px #fff;
  border-radius: 100%;
 
}
.path-pesta{
  position: absolute;
    left: 28px;
    width: 83px;
    top: -7px;
    height: auto;
}
.ask-title{
  color: #fff;
  font-size: 10px;
  line-height: 10px;
  position: absolute;
  left: 28px;
  top: 30px;
}

</style>